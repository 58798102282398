import {Gallery, Carousel, Navigation, Listing, LocationManager} from "./components";
import { Staff } from "./components/staff";

const app = () => {
  const gallery = new Gallery();
  gallery.attach();

  const carousel = new Carousel();
  carousel.attach();

  const navigation = new Navigation();
  navigation.attach();

  const listing = new Listing();
  listing.attach();

  const staff = Staff();
  staff.attach();

  const locationManager = LocationManager();
  locationManager.attach();
};

window.app = app;