import {Overlay} from "./overlay";

const content = `
    <div class="staff-list__slideover">
    <div class="heading">
        <div class="profile--image" style="background-image:url('#IMAGE')">
            <img class="sr-only" src="#IMAGE" alt="#NAME">
        </div>
        <div class="details">
            <h2>#NAME</h2>
            <p>#JOB</p>
        </div>
    </div>
    <div class="text">
        <p>#SUMMARY</p>
    </div>
</div>
`;
const Staff = () => {

    const init = () => {
        const staffList = document.querySelectorAll('.js-staff a.btn');
        staffList.forEach(staff =>
            staff.addEventListener('click', (e) => {
                e.preventDefault();
                const summary = staff.nextElementSibling.innerHTML;
                const name = staff.dataset.name;
                const image = staff.dataset.image;
                const jobTitle = staff.dataset.job;
                Overlay.show(constructHtml({summary, name, image, title: jobTitle}));
            })
        )
    }

    const constructHtml = ({name, title, image, summary}) => {
        return content.replace('#IMAGE', image)
        .replace(/#NAME/g, name)
        .replace(/#JOB/g, title)
        .replace(/#SUMMARY/g, summary);
    }

    return {attach: init}
}

export {Staff};
