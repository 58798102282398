const OverlayManager = () => {

    const show = (content, onClose) => {
        const overlay = document.querySelector('.overlay');
        const draw = document.querySelector('.drawer');
        const drawBody = draw.querySelector('.body');
        const closeBtn = draw.querySelector('.js-close');
        drawBody.innerHTML = content;

        closeBtn.addEventListener('click', () => {
            overlay.classList.remove('show');
            draw.classList.remove('show');
            if(onClose) {
                onClose();
            }
        });

        overlay.classList.add('show');
        draw.classList.add('show');
    }

    return {show};
}

const Overlay = OverlayManager();
export {Overlay};