const Carousel = () => {

    const carousels = Array.from(document.querySelectorAll(".carousel"));

    let carouselOpts = [];

    const _setupTimer = () => {
        if(carousels.length > 0) {
            carouselOpts = carousels.map((carousel, index) => ({carousel: carousel, speed:parseInt(carousel.dataset.speed), timer: null, index: 0, id: index}));
            carouselOpts.forEach(opt => {
                opt.timer = setInterval(() => _iterateSlides(opt.id), opt.speed);
            });
        }

    };

    const _iterateSlides = (i) => {
        const opts = carouselOpts[i];

        const carousel = opts.carousel;

        const slides = carousel.querySelectorAll(".image-container .image");
        const dots = carousel.querySelectorAll(".image-container .dots .dot");
        const caption = carousel.querySelector("p.caption");
        const index = parseInt(carousel.dataset.index);

        slides.forEach(slide => slide.classList.remove('active'));
        dots.forEach(dot => dot.classList.remove('active'));
        const slide = slides[index];
        slide.classList.add('active');
        const dot = dots[index];

        carousel.dataset.index = index+1;
        dot.classList.add('active');
        slide.style.backgroundPosition = slide.dataset.position || 'center';
        const cap = slide.dataset.caption || "";

        caption.innerHTML = cap;

        if(carousel.dataset.index >= slides.length) {
            carousel.dataset.index = 0;
        }
    }

    const _destroy = () => {
        if(carouselOpts && carouselOpts.length > 0) {
            carouselOpts.forEach(opts => opts.timer = null);
        }
    }

    const _init = () => {
        _setupTimer();
    };

    return {
        attach: _init
    }
}

export {Carousel}
