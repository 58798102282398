const Gallery = () => {
    const mainImages = document.querySelectorAll(".gallery .main .image");
    const thumbnails = document.querySelectorAll(".gallery .thumbs .image");
    const caption = document.querySelector(".gallery .main p.caption");
    let timer = null;
    let index = 0;

    const _setupTimer = () => {
        if(thumbnails.length > 0) {
            timer = setInterval(_iterateThumbs, 3000);
        }
    };

    const _iterateThumbs = () => {
        thumbnails.forEach(thumbnail => thumbnail.classList.remove('active'));
        mainImages.forEach(mainImage => mainImage.classList.remove('active'));
        const thumb = thumbnails[index];
        thumb.classList.add('active');

        const main = mainImages[index];
        index += 1;

        main.classList.add('active');

        const cap = thumb.dataset.caption || "";

        caption.innerHTML = cap;
        
        if(index >= thumbnails.length) {
            index = 0;
        }
    }

    const _destroy = () => {
        timer = null;
    }

    const _init = () => {
        _setupTimer();
    };

    return {
        attach: _init
    }
}

export {Gallery};