const Listing = () => {

    const makeEqualHeights = () => {
        const rows = _getRows();
        if(window.innerWidth > 767) {
            rows.forEach(row => {
                _setCardImageHeight(row);
                _setCardHeight(row);
            });
        }
        else {
            rows.forEach(row => {
                _resetCardImageHeight(row);
                _resetCardHeight(row);
            });
        }
    }

    const _getRows = () => {
        return document.querySelectorAll("div.article-row") || [];
    }

    const _resetCardHeight = (row) => {
        const cards = _getCards(row);
        cards.forEach(card => {
            card.style.height = "auto";
        });

        return cards;
    }

    const _resetCardImageHeight = (row) => {
        const cards = _getCards(row);
        cards.forEach(card => {
            const image = card.querySelector(".card--image");
            image.style.height = "auto";
        });

        return cards;
    }

    const _setCardHeight = (row) => {
        
        const cards = _resetCardHeight(row);

        const rowHeight = row.clientHeight;
        console.log(cards);

        cards.forEach(card => {
            card.style.height = rowHeight + 'px';
        });
    }

    const _setCardImageHeight = (row) => {
        let maxHeight = 0;
        const cards = _resetCardImageHeight(row);
        cards.forEach(card => {
            const image = card.querySelector(".card--image");
            if(image) {
                maxHeight = image.clientHeight > maxHeight ? image.clientHeight : maxHeight;
            }

            console.log(maxHeight);
        });

        cards.forEach(card => {
            const image = card.querySelector(".card--image");
            if(image) {
                image.style.height = maxHeight + "px";
            }
        })
    }

    const _getCards = (row) => {
        return row.querySelectorAll(".card") || [];
    }

    const _init = () => {
        makeEqualHeights();
        window.addEventListener('resize', makeEqualHeights);
    }

    return {
        attach : _init
    }
}

export {Listing}