function setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    let expires = "expires="+ d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

const LocationManager = () => {

    const init = () => {
        const primaryBtn = document.querySelector(".modal .buttons button.primary");
        const cancelBtn = document.querySelector(".modal .buttons button.cancel");
        const modalWrapper = document.querySelector(".modal-wrapper");

        const domain = primaryBtn.dataset.domain;
        primaryBtn.addEventListener("click", () => {
            window.location.href = `https://parabledance${domain}`
        });

        cancelBtn.addEventListener("click", (e) => {
            e.preventDefault();
            setCookie("pd-hs-modal", 1, 1);
            modalWrapper.remove();
        })

    }

    return {attach: init}
}

export {LocationManager}