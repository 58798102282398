const Navigation = () => {
    const buttonOpen = document.querySelector('button.mobile-menu-open');
    const buttonClose = document.querySelector('button.mobile-menu-close');
    const mobileNavigation = document.querySelector('nav.mobile');

    const navFolders = document.querySelectorAll('.mobile-parent')
    const _init = () => {
        buttonOpen.addEventListener('click', () => {
            mobileNavigation.classList.toggle('nav-open');
        });

        buttonClose.addEventListener('click', () => {
            mobileNavigation.classList.toggle('nav-open');
        });

        navFolders.forEach(folder => folder.addEventListener("click", () => {
            folder.querySelector("ul.mobile-children").classList.toggle("show");
        }));
    }

    return {
        attach: _init
    };
}

export {Navigation};